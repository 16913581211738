
import React from 'react'
import ReactDOM from 'react-dom'
import PubSub from 'pubsub-js'
import jQuery from 'jquery'
  
class Flash extends React.Component {

  constructor(props) {
    super(props)
    this.handleClose   = this.handleClose.bind(this)
    this.handleMessage = this.handleMessage.bind(this)
    this.state = {
      message: '',
      sub: '',
      code: 0
    }

  }

  componentDidMount() {
    const self = this
    PubSub.subscribe('FLASH_TOGGLE', self.handleMessage)
  }

  handleClose() {
    this.setState({ code: 0 })
  }

  handleMessage( msg, data ) {
    this.setState({ 
      message: data.message,
      sub: data.sub,
      code: data.code 
    })
  }

  render() {

    let classes = 'ui message'
    classes += (this.state.code == 0) ? ' hidden' : ''
    classes += (this.state.code == 200) ? ' positive' : ''
    classes += (this.state.code !== 200) ? ' negative' : ''

    let style = {
      paddingBottom: 0,
      paddingTop: 0,
      marginBottom: "1rem"
    }

    let styleInner = {
      marginTop: ".8rem"
    }

    return (
      <div className="sixteen wide column" style={ style }>
        <div className={ classes } style={ styleInner } >
          <i className="close icon" onClick={ this.handleClose }></i>
          <div className="header">
            { this.state.message }
          </div>
          <p>
            { this.state.sub }
          </p>
        </div>
      </div>      
    )
  }
}

// render components
const div = jQuery('#flash_component')
if ( div.length ) {
  const props = div.data('props')
  ReactDOM.render(<Flash {...props} />, document.getElementById('flash_component'))
}