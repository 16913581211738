
import React from 'react'
import ReactDOM from 'react-dom'
import { ReactSVGPanZoom } from 'react-svg-pan-zoom'
import PubSub from 'pubsub-js'
import jQuery from 'jquery'

class Seatmap extends React.Component {

  constructor(props, context) {
    super(props, context)
    this.Viewer = null
    this.handleTypes       = this.handleTypes.bind(this)
    this.handleAddItem     = this.handleAddItem.bind(this)
    this.handleRemoveItem  = this.handleRemoveItem.bind(this)
    this.handleBatchRemove = this.handleBatchRemove.bind(this)
    this.processObjectKeys = this.processObjectKeys.bind(this)
    this.state = {
      width: 0,
      height: 0,
      svg: this.props.svg,
      types: [],
      tickets: [],
      order: []
    }
  }

  componentWillMount() {

    const self = this
    const tickets = this.props.tickets

    // Subscribe
    PubSub.subscribe('TYPES_GET', this.handleTypes)
    PubSub.subscribe('SEATMAP_CIRCLE_CLICK', this.handleAddItem)
    PubSub.subscribe('CART_REMOVE', this.handleRemoveItem)
    PubSub.subscribe('CART_BATCH_REMOVE', this.handleBatchRemove)

    // set resize event
    const getBlockSize = () => {     

      const width = jQuery('#event_seatmap').width()
      const height = 500

      self.setState({
        width: width,
        height: height
      })

    }
    getBlockSize()
    jQuery(window).on('resize', () => {
      getBlockSize()
    })

    // process json
    if ( this.props.svg ) {
      const svg = this.props.svg
      const newSVG = this.processObjectKeys( svg, tickets )
      this.setState({ svg: newSVG })
    }

  }

  componentDidMount() {

    // set jquery secret
    var token = jQuery( 'meta[name="csrf-token"]' ).attr( 'content' )

    jQuery.ajaxSetup( {
      beforeSend: function ( xhr ) {
        xhr.setRequestHeader( 'X-CSRF-Token', token )
      }
    })

    // set viewer
    this.Viewer.fitToViewer()

    // remove loading state
    jQuery('#event_seatmap').removeClass('loading').attr('style', '')

  }

  handleTypes( msg, data ) {

    const self = this
    const svg = this.state.svg
    const tickets = this.state.tickets

    // check tickets and save data
    if ( JSON.stringify( tickets ) !== JSON.stringify( data.tickets ) ) {
      const newSVG = this.processObjectKeys( svg, tickets )
      this.setState({ 
        types: data.types, 
        tickets: data.tickets,
        svg: newSVG 
      })  
    } else {
      this.setState({ 
        types: data.types, 
        tickets: data.tickets 
      })
    }   

  }

  handleAddItem( msg, data ) {

    const self = this
    const svg = this.state.svg
    let order = this.state.order

    // find item
    let item = _.get( svg, data._path )

    // find type
    const type = _.find( self.state.types, ['uid', data._type] )

    // check if not more then limit
    const items = _.filter( order, ['uid', type.uid] )
    if ( items.length < type.limit ) {

      // set item disabled
      item._disabled = true

      // prepare type
      type.seat = data._seat
      type.row  = data._row
      type.hash = data._hash
      type.path = data._path

      // save data
      order.push( type )
      self.setState({ 
        order: order, 
        svg: svg 
      })

      // publish event
      PubSub.publish('CART_ADD', type)
    }

  }

  handleRemoveItem( msg, data ) {

    const svg = this.state.svg
    let order = this.state.order
    let item = order[ data ]

    // remove item from order
    order = _.reject( order, ['uid', item.uid] )

    // set disabled=false
    let circle = _.get( svg, item.path )
    circle._disabled = false

    // save data
    this.setState({ 
      order: order,
      svg: svg
    })

  }

  handleBatchRemove( msg, data ) {

    var order = this.state.order
    data.map(function (i, index) {

      order = order.filter(function (item, index) {
        return i !== index
      })

    })
    this.setState({ order: order })

  }

  processObjectKeys( obj, tickets ) {

    const self = this

    for ( let key in obj ) {

      // group parsing
      if ( key == 'g' ) {
        _.each( obj[key], ( item, index ) => {
          self.processObjectKeys( item, tickets )
        })
      }

      // circle parsing
      if ( key == 'circle' ) {
        _.each( obj[key], ( item, index ) => {
          let ticket = _.indexOf( tickets, item._hash )
          if ( ticket >= 0 ) {
            item._disabled = true
          }
        })
      }

    }

    return obj

  }

  render () {

    const self = this
    const svg = this.state.svg
    const tool = this.state.tool

    return (
      <div>

        <style>
          { this.state.types.map((item) => {
            return `.type_${item.uid} { fill: ${item.color} !important }`
          })}
        </style>

        <div className="ui icon buttons">
          <button className="ui button" onClick={event => this.Viewer.zoomOnViewerCenter(1.1)}>
            <i className="zoom icon"></i> Zoom In
          </button>
          <button className="ui button" onClick={event => this.Viewer.zoomOnViewerCenter(0.9)}>
            <i className="zoom out icon"></i> Zoom Out
          </button>
          <button className="ui button" onClick={event => this.Viewer.fitToViewer()}>
            <i className="radio icon"></i> Reset
          </button>
        </div>

        <div className="seatmap">
          <Seatmap_Tooltip types={ this.state.types } cur={ this.props.cur } />
          <ReactSVGPanZoom
            style={{outline: "1px solid #CCC"}}
            background={'#FFF'}
            tool="auto"
            detectAutoPan={ false }
            preventPanOutside={ true }
            toolbarPosition="none"
            width={ self.state.width } height={ self.state.height } ref={Viewer => this.Viewer = Viewer}>

            <svg id="seatmap" width={ parseInt( svg._width, 10 ) } height={ parseInt( svg._height, 10 ) }>            
              <g>
                { Seatmap_renderSVG( svg, tool ) }
              </g>            
            </svg>
          </ReactSVGPanZoom>
        </div>
          
      </div>
    )
  }

}

class Seatmap_Group extends React.Component {

  constructor(props) {
    super(props)
  }

  render () {

    const obj = this.props.item
    const tool = this.props.tool

    return (
      <g>
        { Seatmap_renderSVG( obj, tool ) }
      </g>      
    )
  }
}

class Seatmap_Circle extends React.Component {

  constructor(props) {
    super(props)
    this.handleClick = this.handleClick.bind(this)
    this.handleMouseOver = this.handleMouseOver.bind(this)
    this.handleMouseLeave = this.handleMouseLeave.bind(this)
  }

  handleClick() {
    const self = this
    const item = this.props.item
    
    // do nothing if no type or is disabled
    if ( item._type && !item._disabled ) {
      PubSub.publish('SEATMAP_CIRCLE_CLICK', self.props.item )
    }
  }

  handleMouseOver( e ) {
    const item = this.props.item
    item.type &&
    PubSub.publish('SEATMAP_CIRCLE_MOUSE_OVER', {
      item: item,
      x: e.nativeEvent.offsetX,
      y: e.nativeEvent.offsetY,
    })
  }

  handleMouseLeave( e ) {
    const item = this.props.item
    PubSub.publish('SEATMAP_CIRCLE_MOUSE_LEAVE', {
      item: item
    })
  }

  render () {

    const item = this.props.item
    const type = item._type ? `type_${item._type}` : ''
    const disabled = this.props.item._disabled ? 'disabled' : ''

    return (      
      <circle className={ `c_${item._hash} ${type} ${disabled}` } 
              cx={item._cx} cy={item._cy} r={item._r}
              data-seat={ item._seat } data-row={ item._row } data-hash={ item._hash }
              onClick={ this.handleClick }
              onMouseOver={ this.handleMouseOver }
              onMouseLeave={ this.handleMouseLeave } />
    )
  }
}

class Seatmap_Path extends React.Component {

  render () {

    const item = this.props.item

    return (      
      <path d={item._d} fill={item._fill} />
    )
  }
}

class Seatmap_Rect extends React.Component {

  render () {

    const item = this.props.item

    return (      
      <rect x={item._x} y={item._y} fill={item._fill} width={item._width} height={item._height} />
    )
  }
}

class Seatmap_Tooltip extends React.Component {

  constructor(props) {
    super(props)
    this.handleTooltipShow = this.handleTooltipShow.bind(this)
    this.handleTooltipHide = this.handleTooltipHide.bind(this)
    this.state = {
      hash: null,
      seat: 0,
      row: 0,
      type: {},
      x: 0,
      y: 0,
      visible: false
    }
  }

  componentDidMount() {
    PubSub.subscribe('SEATMAP_CIRCLE_MOUSE_OVER', this.handleTooltipShow)
    PubSub.subscribe('SEATMAP_CIRCLE_MOUSE_LEAVE', this.handleTooltipHide)
  }

  handleTooltipShow( msg, data ) {
    const self = this
    const type = _.find( self.props.types, ['uid', data.item._type] )
    this.setState({
      hash: data.item._hash,
      seat: data.item._seat,
      row: data.item._row,
      type: type,
      x: data.x,
      y: data.y,
      visible: true
    })
  }

  handleTooltipHide( msg, data ) {
    const self = this
    if ( this.state.hash == data.item._hash ) {
      this.setState({
        hash: null,
        seat: 0,
        row: 0,
        x: 0,
        y: 0,
        type: {},
        visible: false
      })
    }
  }

  render() {

    let classes = 'seatmap__tooltip'
    classes += this.state.visible ? ' visible' : ''

    let style = {
      top: this.state.y + 15,
      left: this.state.x + 15
    }

    return (
      <div className={ classes } style={ style } >
        Seat: { this.state.seat }, Row: { this.state.row },<br/>
        Type: { this.state.type.title },<br/>
        <strong>
          Price: { this.state.type.price }{ this.props.cur }
        </strong>
      </div>
    )
  }

}

const Seatmap_renderSVG = (obj, tool) => {

  let items = null
  for ( let key in obj ) {

    let value = obj[key]
    let i

    // if group
    if ( key === 'g' ) {
      if ( _.isArray( value ) ) {
        items = value.map((item, index) => {
          return <Seatmap_Group key={ index } item={ item } tool={ tool } />
        })
      } else {
        items.push( <Seatmap_Group key={ Math.random().toString(36).slice(3) } item={ value } tool={ tool } /> )
      }
    }

    // if circle
    if ( key === 'circle' ) {
      if ( _.isArray( value ) ) {
        items = value.map((item, index) => {
          return <Seatmap_Circle key={ index } item={ item } tool={ tool } />
        })
      } else {
        items.push( <Seatmap_Circle key={ Math.random().toString(36).slice(3) } item={ value } tool={ tool } /> )
      }
    }

    // if rect
    if ( key === 'rect' ) {
      if ( _.isArray( value ) ) {
        items = value.map((item, index) => {
          return <Seatmap_Rect key={ index } item={ item } tool={ tool } />
        })
      } else {
        items.push( <Seatmap_Rect key={ Math.random().toString(36).slice(3) } item={ value } tool={ tool } /> )
      }
    }

    // if path
    if ( key === 'path' ) {
      if ( _.isArray( value ) ) {
        items = value.map((item, index) => {
          return <Seatmap_Path key={ index } item={ item } tool={ tool } />
        })
      } else {
        items.push( <Seatmap_Path key={ Math.random().toString(36).slice(3) } item={ value } tool={ tool } /> )
      }
    }

  }

  return items

}

// render components
const div = jQuery('#seatmap_component')
if ( div.length ) {
  const props = div.data('props')
  ReactDOM.render(<Seatmap {...props} />, document.getElementById('seatmap_component'))
}
