
import React from 'react'
import ReactDOM from 'react-dom'
import PubSub from 'pubsub-js'
import jQuery from 'jquery'

class Seatmap_Types extends React.Component {

  constructor(props) {
    super(props)
    this.handleGetTypes    = this.handleGetTypes.bind(this)
    this.setRefresh        = this.setRefresh.bind(this)
    this.handleAddItem     = this.handleAddItem.bind(this)
    this.handleRemoveItem  = this.handleRemoveItem.bind(this)
    this.handleBatchRemove = this.handleBatchRemove.bind(this)
    this.state = {
      types: [],
      order: [],
      tickets: [],
      countdown: 60
    }
  }

  componentDidMount() {

    const self = this

    // subscribe events
    PubSub.subscribe('CART_ADD', this.handleAddItem)
    PubSub.subscribe('CART_REMOVE', this.handleRemoveItem)
    PubSub.subscribe('CART_BATCH_REMOVE', this.handleBatchRemove)

    // get types
    self.handleGetTypes()
    self.setRefresh()

  }

  handleGetTypes( msg, data ) {

    const self = this
    let uid = this.props.uid

    jQuery("#loader").removeClass("hidden")
    jQuery.ajax({
      url: `/api/events/${uid}`,
      type: 'GET',
      dataType: 'json'
    }).done( data => {

      self.setState({ 
        types: data.types, 
        tickets: data.tickets 
      })
      PubSub.publish('TYPES_GET', data )
      //self.processMapAndTypes()
      jQuery("#loader").addClass("hidden")

    }).fail((xhr, status, err) => {
      console.error(this.props.url, status, err.toString())
    })

  }

  processMapAndTypes() {

    // Vars
    var s = Snap("#seatmap")
    var types = this.state.types
    var order = this.state.order
    var selected = []

    // mark map types
    this.state.types.map(function (item, index) {

      if ( s.select(".type-" + item.uid) ) {
        types[ index ].map = true
      } else {
        types[ index ].map = false
      }

    })

    // clean booked entries
    s.selectAll(".booked").forEach(function(e){

      e.removeClass("booked")

    })

    // process booked entries
    this.state.tickets.length && this.state.tickets.map(function (ticket, index) {

      for ( var i = 0; i < order.length; i++ ) {

        if ( ticket.seathash == order[i].hash ) {            
          selected.push(i)
        }

      }

      s.select("#id-" + ticket.seathash).addClass("booked")

    })

    //console.log( selected )

    selected.length && PubSub.publish('CART_BATCH_REMOVE', selected)

    // flash notice
    selected.length && PubSub.publish('FLASH_TOGGLE', {
      message: "Your cart has been modified accordingly to new ticket data.",
      sub: "Result of processing.",
      code: 200
    })

    // set types
    this.setState({ types: types })

  }

  setRefresh() {

    const self = this

    // renew countdown
    const countdown = setInterval(() => {

      let time = self.state.countdown - 1

      self.setState({
        countdown: time
      })

      if ( self.state.countdown == 0 ) {
        self.handleGetTypes()
        self.setState({
          countdown: 60
        })
      }

    }, 1000)

  }

  handleAddItem( msg, data ) {

    let order = this.state.order
    order.push(data)
    !this.props.user && jQuery('#modal-login').modal('show')
    this.setState({ order: order })

  }

  handleRemoveItem( msg, data ) {

    let order = this.state.order
    order = order.filter( (item, index) => {
      return data !== index
    })
    this.setState({ order: order })

  }

  handleBatchRemove( msg, data ) {

    let order = this.state.order
    data.map((i, index) => {
      order = order.filter((item, index) => {
        return i !== index
      })
    })
    this.setState({ order: order })

  }

  render() {

    const self = this
    let selected = false
    let styles = ""
    const listItems = this.state.types.map( (item, index) => {

      let disabled = false
      let order = self.state.order
      order = order.filter(function (it, i) {
        return item.id == it.id 
      })
      if ( order.length >= item.limit ) {
        disabled = true
      }      

      return (
        <Seatmap_Type key={index} disabled={disabled} cur={self.props.cur} data={item} />
      )
    })

    return (
      <table className="ui padded table unstackable">
        <thead>
          <tr>
            <th>Ticket type</th>
            <th>Price</th>
            <th>Tickets left</th>
            <th className="right aligned"></th>
          </tr>
        </thead>
        <tbody>
          {listItems}
        </tbody>
        <tfoot className="full-width">
          <tr>
            <th colSpan="6">
              Number of left tickets is being updated each minute.<br/>
              Time till next update: {this.state.countdown}s
            </th>
          </tr>
        </tfoot>
      </table>
    )
  }
}

class Seatmap_Type extends React.Component {

  constructor(props) {
    super(props)
    this.handleClick = this.handleClick.bind(this)
    this.state = {
      map: false
    }
  }

  handleClick() {
    PubSub.publish('CART_ADD', this.props.data)
  }

  componentDidMount() {
    const items = jQuery(`.seatmap .type_${this.props.data.uid}`).length
    if ( items > 0 ) {
      this.setState({
        map: true
      })
    }
  }

  render() {

    let classes = 'ui small blue animated fluid button'
    classes += (this.props.disabled || (this.props.data.left == 0) ) ? ' disabled' : ''
    classes += this.state.map ? ' disabled' : ''

    var style = {
      color: this.props.data.color
    }

    return (
      <tr>
        <td>
          <i className="circle icon" style={ style }></i>
          { this.props.data.title }
        </td>
        <td>{ this.props.data.price }{ this.props.cur }</td>
        <td>{ this.props.data.left } / { this.props.data.amount }</td>        
        <td className="right aligned">
          <div className={ classes } onClick={ this.handleClick }>
            <div className="visible content">
              { this.state.map ? 'Choose on map' : 'Get' }
            </div>
            <div className="hidden content">
              <i className={ this.props.data.map ? "marker icon" : "cart icon" }></i>
            </div>
          </div>
        </td>          
      </tr>
    )

  }
}

// render components
const div = jQuery('#seatmap_types_component')
if ( div.length ) {
  const props = div.data('props')
  ReactDOM.render(<Seatmap_Types {...props} />, document.getElementById('seatmap_types_component'))
}