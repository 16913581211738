
import React from 'react'
import ReactDOM from 'react-dom'
import PubSub from 'pubsub-js'
import jQuery from 'jquery'

class Seatmap_Cart extends React.Component {
  
  constructor(props) {
    super(props)
    this.handleOrder       = this.handleOrder.bind(this)
    this.handleTypes       = this.handleTypes.bind(this)
    this.handleAddItem     = this.handleAddItem.bind(this)
    this.handleRemoveItem  = this.handleRemoveItem.bind(this)
    this.handleBatchRemove = this.handleBatchRemove.bind(this)
    this.state = {
      types: [],
      tickets: [],
      order: [],
      block: false
    }
  }

  handleOrder( msg, data ) {

    const self = this
    const uid = this.props.uid
    var order = []
        order = _.map( self.state.order , (item) => {
          return { id: item.id, seat: item.seat, row: item.row, hash: item.hash }
        })

    console.log( order )
  
    jQuery("#loader").removeClass("hidden")
    jQuery.ajax({
      url: `/api/events/${uid}/order`,
      type: `POST`,
      data: {
        order: JSON.stringify( order )
      },
      dataType: `json`,
      beforeSend: (xhr) => {
        xhr.setRequestHeader('X-CSRF-Token', jQuery('meta[name="csrf-token"]').attr('content'))
      }
    }).done( data => {

      jQuery("#loader").addClass("hidden")
        
      if ( data.code == 200 ) {
        this.setState({ block: true })
        setTimeout(function(){
          window.location = "/profile/orders"
        }, 2000)
      }

      PubSub.publish('FLASH_TOGGLE', {
        message: data.message,
        sub: 'Result of processing.',
        code: data.code 
      })

    }).fail( data => {

      console.log( data )

      jQuery("#loader").addClass("hidden")
      PubSub.publish('FLASH_TOGGLE', {
        message: "Some error occured.",
        sub: data.message,
        code: data.status 
      })
      console.error(xhr, status, err.toString())

    })

  }

  handleTypes( msg, data ) {

    this.setState({ 
      types: data.types, 
      tickets: data.tickets 
    })

  }

  handleAddItem( msg, data ) {

    let order = this.state.order
    order.push(data)
    this.setState({ order: order })

  }

  handleRemoveItem( msg, data ) {

    let order = this.state.order
    order = order.filter( (item, index) => {
      return data !== index
    })
    this.setState({ order: order })

  }

  handleBatchRemove( msg, data ) {

    let order = this.state.order
    data.map( (i, index) => {

      order = order.filter( (item, index) => {
        return i !== index
      })

    })
    this.setState({ order: order })

  }

  componentDidMount() {

    // set jquery secret
    var token = jQuery( 'meta[name="csrf-token"]' ).attr( 'content' )

    jQuery.ajaxSetup( {
      beforeSend: function ( xhr ) {
        xhr.setRequestHeader( 'X-CSRF-Token', token )
      }
    })

    // subscribe
    PubSub.subscribe('TYPES_GET', this.handleTypes)
    PubSub.subscribe('CART_ADD', this.handleAddItem)
    PubSub.subscribe('CART_REMOVE', this.handleRemoveItem)
    PubSub.subscribe('CART_BATCH_REMOVE', this.handleBatchRemove)

  }

  render() {

    let total = 0
    const self = this
    const listItems = this.state.order.map( (item, index) => {
      total+= item.price
      return (
        <Seatmap_Cart_Item key={index} index={index} cur={self.props.cur} data={item} />
      )
    })

    let table  = { display: "table" }
    let notice = { display: "none" }
    if ( !this.state.order.length ) {
      table.display  = "none"
      notice.display = "block"
    }

    let classes = 'ui right floated small blue labeled icon button'
    classes += !this.props.user ? ' disabled' : ''

    var lastThStyle = {
      overflow: 'hidden'
    }

    return (
      <div>
        <div className="ui segment">
          <h2 className="ui header">
            You chose
            <div className="sub header">
              Tickets that you have chosen
            </div>
          </h2>

          <div style={ notice }>
            Choose something before.
          </div>

          <table className="ui table unstackable" style={ table }>
            <thead>
              <tr>
                <th>Ticket type</th>
                <th>Row</th>
                <th>Seat</th>
                <th>Price</th>
                <th className="right aligned"></th>
              </tr>
            </thead>
            <tbody>
              
              { listItems }
              
            </tbody>
            <tfoot className="full-width">
              <tr>
                <th>
                  <strong>Total:</strong>
                </th>
                <th>
                  <strong>{ total }{ this.props.cur }</strong>
                </th>
                <th colSpan="3" style={lastThStyle}>
                  <div className={ classes } onClick={ this.handleOrder }>
                    <i className="shop icon"></i>
                    Order
                  </div>
                </th>
              </tr>
            </tfoot>
          </table>

        </div>
      </div>
    )
  }
}

class Seatmap_Cart_Item extends React.Component {

  constructor(props) {
    super(props)
    this.handleRemoveItem = this.handleRemoveItem.bind(this)
  }

  handleRemoveItem() {
    PubSub.publish('CART_REMOVE', this.props.index)
  }

  render() {
    return (
      <tr>
        <td>{this.props.data.title}</td>
        <td>{this.props.data.row ? this.props.data.row : "-"}</td>
        <td>{this.props.data.seat ? this.props.data.seat : "-"}</td>
        <td>{this.props.data.price}{this.props.cur}</td>
        <td className="right aligned">
          <div className="ui mini icon buttons">
            <div className="ui orange button" onClick={this.handleRemoveItem}>
              <i className="close icon"></i>
            </div>
          </div>
        </td>
      </tr>
    )
  }
}

// render components
const div = jQuery('#seatmap_cart_component')
if ( div.length ) {
  const props = div.data('props')
  ReactDOM.render(<Seatmap_Cart {...props} />, document.getElementById('seatmap_cart_component'))
}