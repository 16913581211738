
import React from 'react'
import ReactDOM from 'react-dom'
import jQuery from 'jquery'

export default class Favorite extends React.Component {
  constructor(props) {
    super(props)
    this.handleFavorite = this.handleFavorite.bind(this)
    this.state = {
      favorite: this.props.favorite
    }
  }

  componentDidMount() {

    var token = jQuery( 'meta[name="csrf-token"]' ).attr( 'content' )

    jQuery.ajaxSetup( {
      beforeSend: function ( xhr ) {
        xhr.setRequestHeader( 'X-CSRF-Token', token )
      }
    })

  }

  handleFavorite( index ) {

    const self = this

    jQuery.ajax({
      url: self.props.action,
      method: 'POST'
    }).done( (res) => {
      self.setState({ favorite: !self.state.favorite })
    })

  }

  render() {
    return (
      <div className="ui segment">
        <div className={ this.state.favorite ? 'ui teal fluid button' : 'ui red fluid button' }
              onClick={ this.handleFavorite }>
          <i className={ this.state.favorite ? 'empty heart icon' : 'heart icon' }></i>
          { this.state.favorite ? 'Remove from favorites' : 'Add to favorites' }
        </div>
      </div>
    )
  }
}

// render components
const div = jQuery('#favorite_component')
if ( div.length ) {
  const props = div.data('props')
  ReactDOM.render(<Favorite {...props} />, document.getElementById('favorite_component'))
}